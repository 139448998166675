import React, {Component} from 'react';
import { Link } from "gatsby";
import {getTopicNameById} from "../../utils/blogUtils";

class LatestBlogNewsContainerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleItems: 7
    }
  }

  postCount = 7;

  handleShowMore = () => {
    let count = this.state.visibleItems;
    this.setState({visibleItems: count + this.postCount});
  }

  isBrowser = typeof window !== "undefined";

  componentWillMount() {
    this.setState({
      blogData: this.isBrowser ? this.props.blogData : [
        {
          "node": {
            "title": "",
            "url": "",
            "body": "",
            "created": 1700155734530,
            "slug": "",
            "topics": [
              {
                "id": 145800716918
              }
            ],
            "feature_image": {
              "url": "https://6919241.fs1.hubspotusercontent-na1.net/hubfs/6919241/01_library_SC01B_2480x1395px.jpg",
              "alt_text": "locking file cabinets and employee lockers in an office "
            }
          }
        }
      ]
    });

    if (this.isBrowser) {
      setTimeout(() => {
        let firstPostElem = document.getElementsByClassName('post-item')[0];
        let firstPost = this.props.blogData[0];
        firstPostElem.getElementsByClassName('img-responsive')[0].src = firstPost.node.feature_image.url;
        firstPostElem.getElementsByTagName('a')[0].href = '/blog/post/'+firstPost.node.slug;
        console.log('post/'+firstPost.node.slug);
      }, 200)

    }
  }

  render() {

    return (
        <div className="latest-news-container">

          <div className="secondpage category">

            {

              this.state.blogData.slice(0, this.state.visibleItems).map(({node: post}, index) => {
                let topics = post.topics;
                let topicName = topics[0].name;
                let topicId = topics[0].id;

                return (
                    <div key={index}>

                      <div className="post-item">
                        <Link to={'/blog/post/' + post.slug + '/'}>
                          <img className={'img-responsive'} src={post.feature_image.url} alt="blog post"
                               loading="lazy"/>
                        </Link>
                        <a href={'/blog/category/'+topicName+'/'} className={'category-label turn-off'}>{topicName}</a>
                        <h2>{post.title}</h2>
                      </div>
                    </div>
                )

              })
            }
          </div>
          {this.state.visibleItems < this.state.blogData.length && (
              <button
                  onClick={this.handleShowMore}
                  className={'btn btn--orange'}
              >
                Show More
              </button>
          )}
        </div>

    )
  }
}

export default LatestBlogNewsContainerCategory;