import React, { Component } from 'react';
import LatestBlogNewsContainerCategory from './LatestBlogNewsContainerCategory';

export function createMarkup(markup) {
  return { __html: markup }
}

class LatestBlogNewsCat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blogData: this.props.blogData,
      filteredBlogData: this.props.blogData
    }
  }

  isBrowser = typeof window !== "undefined";

  componentWillMount() {
    let newBlogData = this.props.blogData;
    if(this.isBrowser && window.location.toString().indexOf('category') > 0) {
      let urlSplit = window.location.toString().split('/');
      this.setFilter(urlSplit[urlSplit.length-2]);
    } else {
      this.setState({
        blogData: newBlogData
      })
    }
  }

  setFilter = (cat) => {
    let blogData = this.props.blogData;
    let filterBlogData = [];
    for (let i = 0; i < blogData.length; i++) {
      if (blogData[i].node.topics[0].name === cat) {
        filterBlogData.push(blogData[i]);
      }
    }

    this.setState({
      filterBlogData: filterBlogData
    });
  };


  render() {
    let blogTopics = this.props.blogTopics.data.objects;
    return (
        <div>
            <LatestBlogNewsContainerCategory
                blogTopics={blogTopics}
                blogData={this.state.filterBlogData}
                getTopicNameById={this.getTopicNameById}
            />
        </div>
    )
  }
}

export default LatestBlogNewsCat;