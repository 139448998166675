import React, {useEffect, useState} from 'react';
import categories from './categories';
import CustomLocalizedLink from '../../components/locales/custom-localized-link';

let category = categories.data.objects;
const isBrowser = typeof window !== "undefined"

const BlogNav = () => {
  const filteredCats = (category.filter(category => category.in_nav === true));
  const [qs, setQs] = useState('');

  useEffect(() => {
    if(isBrowser) {
      let loc = window.location.toString();
      if(loc.indexOf('retail') === -1 && loc.indexOf('pro-sports') === -1) {
        if(loc.indexOf('category') > 0) {
          let urlSplit = loc.split('/');
          document.getElementsByClassName(urlSplit[urlSplit.length-2])[0].classList.add('selected');
        }
      }
    }
  }, []);

  return (
      <nav>
        {filteredCats.map((item) => (
            <CustomLocalizedLink goto={'/blog/category/'+item.name+'/'} label={item.name} cls={item.name} />
        ))}
      </nav>
  )
};
export default BlogNav;